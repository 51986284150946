import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultManagedGroupLabel: {
    id: 'ManagedGroupAndRoles.defaultManagedGroupLabel',
    defaultMessage: 'Default managed group',
  },
  additionalManagedGroupLabel: {
    id: 'ManagedGroupAndRoles.additionalManagedGroupLabel',
    defaultMessage: 'Additional managed group',
  },
  roleLabel: {
    id: 'ManagedGroupAndRoles.roleLabel',
    defaultMessage: 'Role',
  },
  adminToolAccessLabel: {
    id: 'ManagedGroupAndRoles.adminToolAccessLabel',
    defaultMessage: 'Admin tool access',
  },
  setAdminToolAccess: {
    id: 'ManagedGroupAndRoles.setAdminToolAccess',
    defaultMessage: 'Grant admin tool access',
  },
  disabledAdminToolAccessTooltip: {
    id: 'ManagedGroupAndRoles.disabledAdminToolAccessTooltip',
    defaultMessage: 'Admin tool access setting is not available for TOC/MG20/MG21',
  },
});
