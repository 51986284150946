import React, { FunctionComponent, Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import { css } from 'aphrodite/no-important';
import { StatusMessage } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import ManagedGroupAndRoles, {
  ManagedGroupAndRolesType,
} from '@contactcentre-web/common/ManagedGroupAndRoles';
import type State from '@contactcentre-web/redux-common/types/State';
import type Action from '@contactcentre-web/redux-common/types/Action';

import { selectors, actions } from '../../../../module';

import messages from './messages';
import styles from './styles';

export const FORM_ID = 'changeManagedGroupAndRole';

interface StateProps {
  isSubmitting: boolean;
  userHomeManagedGroupId?: string;
  isGlobalRoleUser?: boolean;
  hasInternalAdminToolRole?: boolean;
}

interface Props extends StateProps {
  userId: string;
  editingUserRoles: ManagedGroupAndRolesType;
}

interface FormData {
  managedGroupRoles: Array<{ managedGroup: string; role: string }>;
}

export const ChangeRoleForm: FunctionComponent<Props & InjectedFormProps<FormData, Props>> = ({
  editingUserRoles,
  userHomeManagedGroupId,
  handleSubmit,
  isSubmitting,
  isGlobalRoleUser,
  hasInternalAdminToolRole,
}) => {
  if (isGlobalRoleUser) {
    return (
      <StatusMessage status="warning">
        <FormattedMessage {...messages.isGlobalRoleUser} />
      </StatusMessage>
    );
  }

  return (
    <form id={FORM_ID} onSubmit={handleSubmit}>
      <FieldArray
        name="managedGroupRoles"
        component={ManagedGroupAndRoles}
        props={{
          availableManagedGroupsAndRoles: editingUserRoles,
          currentManagedGroupId: userHomeManagedGroupId,
          hasInternalAdminToolRole: hasInternalAdminToolRole,
        }}
        rerenderOnEveryChange
      />
      <div className={css(styles.submitButtonContainer)}>
        <Button
          type="submit"
          variant="primary"
          size="xlarge"
          testId="change-role-confirm-button"
          loading={isSubmitting}
          styleSheet={styles.submitButton}
        >
          <FormattedMessage {...messages.update} />
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state: State) => ({
  isSubmitting: selectors.isChangeRolePending(state),
  userHomeManagedGroupId: selectors.getUserHomeManagedGroupId(state),
  isGlobalRoleUser: selectors.isGlobalRoleUser(state),
  hasInternalAdminToolRole: selectors.hasInternalAdminToolRole(state),
});

export const validate = ({
  managedGroupRoles = [],
}: FormData): Record<string, Record<string, string>> => ({
  managedGroupRoles: managedGroupRoles?.reduce(
    (errors, managedGroupRole, index) => ({
      ...errors,
      [index]: {
        managedGroup: !managedGroupRole?.managedGroup ? 'required' : undefined,
        role: !managedGroupRole?.role ? 'required' : undefined,
      },
    }),
    {}
  ),
});

export default connect(mapStateToProps)(
  reduxForm<FormData, Props>({
    form: FORM_ID,
    validate,
    enableReinitialize: true,
    onSubmit: ({ managedGroupRoles }: FormData, dispatch: Dispatch<Action>, { userId }: Props) =>
      dispatch(actions.changeRoleAttempt(userId, managedGroupRoles)),
  })(ChangeRoleForm)
);
