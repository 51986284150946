import { StyleSheet } from 'aphrodite/no-important';
import { spacers, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  container: {
    display: 'flex',
    marginTop: spacers.dp_s8,
  },
  containerSelect: {
    width: '280px',
  },
  roleContainer: {
    marginLeft: spacers.dp_s16,
  },
  buttonsContainer: {
    display: 'flex',
    margin: `36px 0 0 ${spacers.dp_s4}`,
    height: '25px',
  },
  checkboxContainer: {
    padding: `${spacers.dp_s8} 0`,
  },
  disabledCheckboxContainer: {
    color: palettes.dp_app_text_disabled,
  },
  hiddenRow: {
    display: 'none',
  },
});
