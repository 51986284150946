import { css } from 'aphrodite/no-important';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { IconCheck } from '@trainline/depot-web';

import Button from '@contactcentre-web/common/Button';
import { ModalBody, ModalFooter } from '@contactcentre-web/common/Modal';
import StackedLabel from '@contactcentre-web/common/StackedLabel';
import TextField from '@contactcentre-web/common/TextField';
import { validateEmail } from '@contactcentre-web/utils//validation/validators';

import { FORM_ID, SearchRequest } from '../../module';

import messages from './messages';
import styles from './styles';

export interface Props {
  inProgress: boolean;
  submitLabel: ReactElement;
  originalEmail: string;
  buttonTestId: string;
  onSubmitForm: (data: SearchRequest) => void;
}

export const EmailSearchForm = ({
  handleSubmit,
  onSubmitForm,
  inProgress,
  submitLabel,
  submitSucceeded,
  submitting,
  valid,
  buttonTestId,
}: Props & InjectedFormProps<SearchRequest, Props>) => (
  <form onSubmit={handleSubmit((data) => onSubmitForm(data))}>
    <ModalBody styleSheet={styles.field_container}>
      <StackedLabel
        label={<FormattedMessage {...messages.field_email} />}
        styleSheet={{
          label: styles.label,
          container: styles.label_container,
        }}
      >
        <TextField
          data-test="moveOrderEmail"
          name="email"
          disabled={inProgress || (submitSucceeded && valid)}
          styleSheet={{
            container: styles.field,
            input: !submitting && submitSucceeded && valid ? styles.field_ok : undefined,
          }}
          messages={messages}
        />
      </StackedLabel>
      {!submitting && submitSucceeded && valid && <IconCheck className={css(styles.icon)} />}
    </ModalBody>
    <ModalFooter>
      <Button
        type="submit"
        variant="primary"
        size="large"
        loading={!!inProgress}
        disabled={inProgress || !valid}
        testId={buttonTestId}
      >
        {submitLabel}
      </Button>
    </ModalFooter>
  </form>
);
export const validate = ({ email }: { email: string }) => {
  if (!email)
    return {
      email: 'email_required',
    };

  if (!validateEmail(email))
    return {
      email: 'email_invalid',
    };

  return {};
};

export default reduxForm<SearchRequest, Props>({
  form: FORM_ID,
  validate,
})(EmailSearchForm);
