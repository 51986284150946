import { defineMessages } from 'react-intl';

export default defineMessages({
  detaxeHeader: {
    id: 'UpdateRefundStatus.detaxeHeader',
    defaultMessage: 'Update eDetaxe refund status',
  },
  currentStatus: {
    id: 'UpdateRefundStatus.currentStatus',
    defaultMessage: 'Current status',
  },
  changeStatus: {
    id: 'UpdateRefundStatus.changeStatus',
    defaultMessage: 'Change status',
  },
  selectStatus: {
    id: 'UpdateRefundStatus.selectStatus',
    defaultMessage: 'Please select status',
  },
  rReference: {
    id: 'UpdateRefundStatus.rReference',
    defaultMessage: 'R Reference:',
  },
  rReferenceNumber: {
    id: 'UpdateRefundStatus.rReferenceNumber',
    defaultMessage: 'R Reference number',
  },
  claimAmountTitle: {
    id: 'UpdateRefundStatus.claimAmountTitle',
    defaultMessage: 'Claim amount:',
  },
  claimAmountPlaceholder: {
    id: 'UpdateRefundStatus.claimAmountPlaceholder',
    defaultMessage: 'Amount',
  },
  notes: {
    id: 'UpdateRefundStatus.notes',
    defaultMessage: 'Notes',
  },
  notesText: {
    id: 'UpdateRefundStatus.notesText',
    defaultMessage: 'Notes will be logged against order in TCC',
  },
  updateButton: {
    id: 'UpdateRefundStatus.updateButton',
    defaultMessage: 'Update status',
  },
  validationStatusRequired: {
    id: 'UpdateRefundStatus.validationStatusRequired',
    defaultMessage: 'Update status value must be provided',
  },
  validationClaimAmountRequired: {
    id: 'UpdateRefundStatus.validationClaimAmountRequired',
    defaultMessage: 'Claim amount value must be provided',
  },
  validationNoteField: {
    id: 'UpdateRefundStatus.validationNoteField',
    defaultMessage: 'Notes must be 500 characters or less',
  },
  canceledTrainDisruption: {
    id: 'UpdateRefundStatus.canceledTrainDisruption',
    defaultMessage: 'Cancelled train/Disruption',
  },
  refundedCanceledTrainDisruption: {
    id: 'UpdateRefundStatus.refundedCanceledTrainDisruption',
    defaultMessage: 'Cancelled train/Disruption - Refunded',
  },
  onboardComfortIssues: {
    id: 'UpdateRefundStatus.onboardComfortIssues',
    defaultMessage: 'Onboard comfort issues',
  },
  missedConnection: {
    id: 'UpdateRefundStatus.missedConnection',
    defaultMessage: 'Missed connection',
  },
  deathIllness: {
    id: 'UpdateRefundStatus.deathIllness',
    defaultMessage: 'Death/Illness',
  },
  voidVoucher: {
    id: 'UpdateRefundStatus.voidVoucher',
    defaultMessage: 'Void voucher',
  },
  fareTypeRefunds: {
    id: 'UpdateRefundStatus.fareTypeRefunds',
    defaultMessage: 'Fare type refunds',
  },
  duplicateBooking: {
    id: 'UpdateRefundStatus.duplicateBooking',
    defaultMessage: 'Duplicate booking',
  },
  historicPNR: {
    id: 'UpdateRefundStatus.historicPNR',
    defaultMessage: 'Historic PNR',
  },
  finalizeOptionSNCF: {
    id: 'UpdateRefundStatus.finalizeOptionSNCF',
    defaultMessage: 'Finalize option SNCF',
  },
  railcard: {
    id: 'UpdateRefundStatus.railcard',
    defaultMessage: 'Railcard',
  },
  contactReason: {
    id: 'UpdateRefundStatus.contactReason',
    defaultMessage: 'Contact Reason',
  },
  selectReason: {
    id: 'UpdateRefundStatus.selectReason',
    defaultMessage: 'Please select a contact reason',
  },
  validationReasonRequired: {
    id: 'UpdateRefundStatus.validationReasonRequired',
    defaultMessage: 'Contact Reason must be provided',
  },
  unknownContactReason: {
    id: 'UpdateRefundStatus.unknownContactReason',
    defaultMessage: 'Unknown Contact Reason',
  },
  downloadDossierVoyage: {
    id: 'UpdateRefundStatus.downloadDossierVoyage',
    defaultMessage: 'Download Dossier Voyage',
  },
  pdfDownloadErrorMessage: {
    id: 'UpdateRefundStatus.pdfDownloadErrorMessage',
    defaultMessage:
      'Something went wrong downloading the Dossier Voyage. Please refresh and try again.',
  },
  rightToMakeMistakeCustomerError: {
    id: 'UpdateRefundStatus.rightToMakeMistakeCustomerError',
    defaultMessage: 'Right to make mistake - Customer Error',
  },
});
