import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { css } from 'aphrodite/no-important';
import { WrappedFieldArrayProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { IconPlus, IconMinus } from '@trainline/depot-web';

import { selectors as customerOrderSelectors } from '@contactcentre-web/customer-order/module';
import Checkbox from '@contactcentre-web/common/Checkbox';
import { RoleType } from '@contactcentre-web/hooks/api/useCanManageRoles';

import Select from '../Select';
import Button from '../Button';

import messages from './messages';
import styles from './styles';

export type ManagedGroupAndRolesType = Array<{
  managedGroup: { id: string; name: string };
  roles: Array<{ id: string; name: string; roleType: RoleType }>;
}>;

export type ManagedGroupAndRoleType = { managedGroup?: string; role?: string };

type SelectFieldOptions = Array<{
  label: string;
  value: string;
}>;

interface Props {
  availableManagedGroupsAndRoles: ManagedGroupAndRolesType;
  currentManagedGroupId?: string;
  hasInternalAdminToolRole?: boolean;
}

const ManagedGroupAndRoles = ({
  fields,
  availableManagedGroupsAndRoles,
  currentManagedGroupId,
  hasInternalAdminToolRole,
}: WrappedFieldArrayProps & Props) => {
  const { formatMessage } = useIntl();
  const mgBusinessSettings = useSelector(customerOrderSelectors.getBusinessSettings);
  const managedGroups = availableManagedGroupsAndRoles?.map(({ managedGroup }) => managedGroup);

  const allFields: Array<ManagedGroupAndRoleType> = fields.getAll();

  const homeManagedGroupAdminToolRole = useMemo(() => {
    const homeManagedGroup = availableManagedGroupsAndRoles?.find(
      ({ managedGroup }) => managedGroup?.id === currentManagedGroupId
    );

    return {
      managedGroup: homeManagedGroup?.managedGroup.id,
      role: homeManagedGroup?.roles.find(({ roleType }) => roleType === RoleType.ExternalAdminTool)
        ?.id,
    };
  }, [availableManagedGroupsAndRoles, currentManagedGroupId]);

  const adminToolRoleSelected =
    allFields &&
    allFields
      .filter(Boolean)
      .find(
        (field) =>
          field.managedGroup === homeManagedGroupAdminToolRole.managedGroup &&
          field.role === homeManagedGroupAdminToolRole.role
      );

  const homeManagedGroupAdminToolRoleFieldIndex = adminToolRoleSelected
    ? allFields.indexOf(adminToolRoleSelected)
    : -1;

  const getManagedGroupOptions = (currentIndex: number): SelectFieldOptions => {
    const alreadySelectedManagedGroupsExceptCurrent = allFields
      ?.filter(Boolean)
      .map(({ managedGroup }) => managedGroup)
      .filter((_, index) => index !== currentIndex);

    const managedGroupsAvailableToSelect = managedGroups?.filter((managedGroup) => {
      if (managedGroup.id === homeManagedGroupAdminToolRole.managedGroup && currentIndex === 0) {
        return true;
      } else {
        return !alreadySelectedManagedGroupsExceptCurrent?.includes(managedGroup.id);
      }
    });

    return managedGroupsAvailableToSelect?.map(({ name, id }) => ({
      label: name,
      value: id,
    }));
  };

  const getRolesOptions = (currentIndex: number): SelectFieldOptions => {
    const selectedManagedGroup =
      allFields && allFields.length > 0 && allFields[currentIndex]?.managedGroup;

    const roles = availableManagedGroupsAndRoles?.filter(
      ({ managedGroup }) => managedGroup.id === selectedManagedGroup
    )[0]?.roles;

    return roles
      ?.filter(({ roleType }) => roleType === RoleType.ContactCentre)
      .map(({ id, name }) => ({
        label: name,
        value: id,
      }));
  };

  const isHomeManagedGroup = (currentIndex: number): boolean => {
    const managedGroup: string = fields.get(currentIndex)?.managedGroup;
    return managedGroup === currentManagedGroupId;
  };

  const onCheckboxChange = () => {
    if (adminToolRoleSelected) {
      fields.remove(homeManagedGroupAdminToolRoleFieldIndex);
    } else {
      fields.push({
        managedGroup: homeManagedGroupAdminToolRole.managedGroup,
        role: homeManagedGroupAdminToolRole.role,
      });
    }
  };

  const shouldShowAddManagedGroupIcon = (currentIndex: number): boolean => {
    const isLastField = fields?.length - 1 === currentIndex;
    const adminToolRoleIsLastField = homeManagedGroupAdminToolRoleFieldIndex === fields?.length - 1;
    const afterAdminRole =
      isLastField && currentIndex - homeManagedGroupAdminToolRoleFieldIndex === 1;
    const beforeAdminRole =
      adminToolRoleIsLastField && homeManagedGroupAdminToolRoleFieldIndex - currentIndex === 1;
    return !!(
      fields?.length < managedGroups?.length &&
      fields?.length &&
      currentIndex !== homeManagedGroupAdminToolRoleFieldIndex &&
      (isLastField || beforeAdminRole || afterAdminRole)
    );
  };

  return (
    <section>
      {fields.map((name: string, index: number) => (
        <div
          className={css(
            styles.container,
            index === homeManagedGroupAdminToolRoleFieldIndex && styles.hiddenRow
          )}
          key={index}
        >
          <Select
            label={
              <FormattedMessage
                {...(index === 0
                  ? messages.defaultManagedGroupLabel
                  : messages.additionalManagedGroupLabel)}
              />
            }
            name={`${name}.managedGroup`}
            testId={`managed-group-select-${index}`}
            options={getManagedGroupOptions(index)}
            containerStyle={styles.containerSelect}
            disabled={isHomeManagedGroup(index)}
          />
          <Select
            label={<FormattedMessage {...messages.roleLabel} />}
            name={`${name}.role`}
            testId={`role-select-${index}`}
            options={getRolesOptions(index)}
            containerStyle={[styles.containerSelect, styles.roleContainer]}
          />
          <div className={css(styles.buttonsContainer)}>
            {index > 0 && (
              <Button
                variant="ghost"
                testId={`remove-field-${index}`}
                onClick={() => fields.remove(index)}
              >
                <IconMinus />
              </Button>
            )}
            {shouldShowAddManagedGroupIcon(index) && (
              <Button
                variant="ghost"
                testId={`add-field-${index}`}
                onClick={() => fields.push(undefined)}
              >
                <IconPlus />
              </Button>
            )}
          </div>
        </div>
      ))}
      {!hasInternalAdminToolRole && (
        <div className={css(styles.checkboxContainer)}>
          <FormattedMessage {...messages.adminToolAccessLabel} />
          <Checkbox
            name="adminToolAccess"
            label={formatMessage(messages.setAdminToolAccess)}
            testId="admin-tool-access-checkbox"
            input={{
              value: !!adminToolRoleSelected,
              onChange: onCheckboxChange,
            }}
            disabled={!mgBusinessSettings?.externalAdminToolAccess}
            tooltipMessage={
              mgBusinessSettings?.externalAdminToolAccess
                ? ''
                : formatMessage(messages.disabledAdminToolAccessTooltip)
            }
          />
        </div>
      )}
    </section>
  );
};

export default ManagedGroupAndRoles;
