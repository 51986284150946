import { cookies, cookiesKeys } from './cookieStorage';

export interface Token {
  access_token: string;
  refresh_token: string;
  expires_at: Date;
  expires_in: number;
  token_type: string;
}

const getToken = (): Token | undefined => {
  const tokenDetails = cookies.getItem(cookiesKeys.AUTH_TOKEN_KEY) as unknown as Token;
  const token = cookies.getItem(cookiesKeys.AUTH_KEY);

  if (!tokenDetails) {
    return undefined;
  }

  return {
    ...tokenDetails,
    expires_at: new Date(tokenDetails.expires_at),
    access_token: token || tokenDetails.access_token,
  };
};

const setToken = (token: Token) => {
  const { access_token, ...tokenDetails } = token;
  cookies.setItem(cookiesKeys.AUTH_KEY, access_token);
  cookies.setItem(cookiesKeys.AUTH_TOKEN_KEY, tokenDetails);
};

const clearToken = () => {
  cookies.removeItem(cookiesKeys.AUTH_TOKEN_KEY);
  cookies.removeItem(cookiesKeys.AUTH_KEY);
};

const tokenIsExpired = (token: Token) => token.expires_at?.getTime() - Date.now() <= 0;

const validTokenExists = (): boolean => {
  const token = getToken();
  return !!token && !tokenIsExpired(token);
};

export default {
  validTokenExists,
  getToken,
  setToken,
  clearToken,
};
