import { useQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';

export enum RoleType {
  AdminTool = 'AdminTool',
  ExternalAdminTool = 'ExternalAdminTool',
  InternalAdminTool = 'InternalAdminTool',
  ContactCentre = 'ContactCentre',
}

type Data = {
  items: Array<{
    managedGroup: { id: string; name: string };
    roles: { id: string; name: string; roleType: RoleType }[];
  }>;
};

export const useCanManageRoles = () =>
  useQuery('canManageRoles', () => request<Data>('/authapi/roles/canmanage'), {
    select: ({ items }) => items,
  });
