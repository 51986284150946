import { StyleSheet } from 'aphrodite/no-important';
import { spacers, borderRadius, palettes } from '@trainline/depot-web/css/themes/trainline';

export default StyleSheet.create({
  default: {
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    border: `1px solid ${palettes.dp_app_border_dark}`,
    borderRadius: borderRadius.dp_r4,
    textAlign: 'center',
    margin: spacers.dp_s4,
    display: 'inline-block',
    cursor: 'pointer',

    ':disabled': {
      borderColor: palettes.dp_comp_input_binary_border_base,
      backgroundColor: palettes.dp_brand_neutral_mid,
      cursor: 'not-allowed',
      ':hover': {
        backgroundColor: palettes.dp_brand_neutral_mid,
      },
    },
  },
  checked: {
    borderColor: palettes.dp_comp_input_binary_selected_background_base,
    background: palettes.dp_comp_input_binary_selected_background_base,
    color: palettes.dp_brand_white,
  },
  checkbox: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  iconStyle: {
    position: 'relative',
    top: 0,
    width: '100%',
    height: '100%',
  },
  inputWithTooltip: {
    borderBottom: '1px dashed',
  },
  inputStyle: {
    display: 'none',
  },
  invalid: {
    border: `solid 1px ${palettes.dp_app_negative_mid}`,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: spacers.dp_s8,
  },
  readOnly: {
    cursor: 'not-allowed',
  },
});
